var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingCreate
    }
  }, [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_c('Stack', {
    staticClass: "gap-2"
  }, [_c('IncorrectInvoiceInfoCard', {
    attrs: {
      "incorrectInvoiceData": _vm.incorrectInvoiceToCreate,
      "isCreate": ""
    },
    on: {
      "update:incorrectInvoiceData": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      },
      "update:incorrect-invoice-data": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      }
    }
  }), _c('BRow', [_c('BCol', {
    class: !_vm.collapseSelectedIncorrectInvoices ? 'pr-md-50' : '',
    attrs: {
      "cols": "12",
      "md": _vm.collapseSelectedIncorrectInvoices ? 12 : 4
    }
  }, [_c('IncorrectInvoiceSelectCard', {
    attrs: {
      "incorrectInvoiceData": _vm.incorrectInvoiceToCreate,
      "collapseSelectedIncorrectInvoices": _vm.collapseSelectedIncorrectInvoices
    },
    on: {
      "update:incorrectInvoiceData": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      },
      "update:incorrect-invoice-data": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      },
      "update:collapseSelectedIncorrectInvoices": function updateCollapseSelectedIncorrectInvoices($event) {
        _vm.collapseSelectedIncorrectInvoices = $event;
      },
      "update:collapse-selected-incorrect-invoices": function updateCollapseSelectedIncorrectInvoices($event) {
        _vm.collapseSelectedIncorrectInvoices = $event;
      }
    }
  })], 1), _c('BCol', {
    class: !_vm.collapseSelectedIncorrectInvoices ? 'px-md-50' : 'mt-50',
    attrs: {
      "cols": "12",
      "md": _vm.collapseSelectedIncorrectInvoices ? 12 : 8
    }
  }, [_c('IncorrectInvoiceDetailCard', {
    attrs: {
      "incorrectInvoiceData": _vm.incorrectInvoiceToCreate,
      "isInvalidIncorrectInvoiceItems": _vm.isInvalidIncorrectInvoiceItems,
      "resetIncorrectInvoices": _vm.resetIncorrectInvoices,
      "isCreate": ""
    },
    on: {
      "update:incorrectInvoiceData": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      },
      "update:incorrect-invoice-data": function updateIncorrectInvoiceData($event) {
        _vm.incorrectInvoiceToCreate = $event;
      }
    }
  }), _c('HStack', {
    staticClass: "gap-3",
    attrs: {
      "center": ""
    }
  }, [_c('BButton', {
    staticClass: "min-width-120",
    attrs: {
      "pill": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.exitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exit')) + " ")]), _c('BButton', {
    staticClass: "min-width-120",
    attrs: {
      "variant": "info",
      "pill": ""
    },
    on: {
      "click": _vm.onCreateIncorrectInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.btn.create')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }