<template lang="">
  <IAmOverlay :loading="loadingCreate">
    <ValidationObserver ref="refFormObserver">
      <Stack class="gap-2">
        <IncorrectInvoiceInfoCard
          :incorrectInvoiceData.sync="incorrectInvoiceToCreate"
          isCreate
        />
        <BRow>
          <BCol
            cols="12"
            :md="collapseSelectedIncorrectInvoices ? 12 : 4"
            :class="!collapseSelectedIncorrectInvoices ? 'pr-md-50' : ''"
          >
            <IncorrectInvoiceSelectCard
              :incorrectInvoiceData.sync="incorrectInvoiceToCreate"
              :collapseSelectedIncorrectInvoices.sync="collapseSelectedIncorrectInvoices"
            />
          </BCol>
          <BCol
            cols="12"
            :md="collapseSelectedIncorrectInvoices ? 12 : 8"
            :class="!collapseSelectedIncorrectInvoices ? 'px-md-50' : 'mt-50'"
          >
            <IncorrectInvoiceDetailCard
              :incorrectInvoiceData.sync="incorrectInvoiceToCreate"
              :isInvalidIncorrectInvoiceItems="isInvalidIncorrectInvoiceItems"
              :resetIncorrectInvoices="resetIncorrectInvoices"
              isCreate
            />

            <HStack
              center
              class="gap-3"
            >
              <BButton
                pill
                class="min-width-120"
                variant="outline-secondary"
                @click="exitHandle"
              >
                {{ $t('invoice.btn.exit') }}
              </BButton>
              <BButton
                variant="info"
                class="min-width-120"
                pill
                @click="onCreateIncorrectInvoiceHandle"
              >
                {{ $t('invoice.incorrectInvoices.btn.create') }}
              </BButton>
            </HStack>
          </BCol>
        </BRow>
      </Stack>
    </ValidationObserver>
  </IAmOverlay>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import {
  computed, onUnmounted, ref, watch,
} from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { BButton, BCol, BRow } from 'bootstrap-vue'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { getToday } from '@/@core/utils/filter'
import store from '@/store'
import router from '@/router'

import IncorrectInvoiceDetailCard from './components/IncorrectInvoiceDetailCard.vue'
import IncorrectInvoiceInfoCard from './components/IncorrectInvoiceInfoCard.vue'
import IncorrectInvoiceSelectCard from './components/IncorrectInvoiceSelectCard.vue'
import useIncorrectInvoiceHandle, { INCORRECT_INVOICE_NOTIFICATION_TYPE, useIncorrectInvoiceCreateHandle } from './useIncorrectInvoiceHandle'
import invoiceStoreModule from '../invoiceStoreModule'
import incorrectInvoiceStoreModule from './incorrectInvoiceStoreModule'

export default {
  components: {
    IncorrectInvoiceDetailCard,
    IncorrectInvoiceSelectCard,
    IncorrectInvoiceInfoCard,
    ValidationObserver,
    BButton,
    BCol,
    BRow,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup() {
    const { refFormObserver } = formValidation()
    const company = computed(() => store.getters['userStore/getAgencyCompany'])

    const { INVOICE_STORE, INCORRECT_INVOICE_STORE } = useIncorrectInvoiceHandle()
    const { createIncorrectInvoice, loadingCreate } = useIncorrectInvoiceCreateHandle()
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)
    if (!store.hasModule(INCORRECT_INVOICE_STORE)) store.registerModule(INCORRECT_INVOICE_STORE, incorrectInvoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
      if (store.hasModule(INCORRECT_INVOICE_STORE)) store.unregisterModule(INCORRECT_INVOICE_STORE)
    })

    const blankIncorrectInvoice = {
      // companyId: companyId.value,
      notificationType: INCORRECT_INVOICE_NOTIFICATION_TYPE.TYPE_1,
      taxReviewNoticeNumber: '',
      dateOfTaxReviewNotice: '',
      placeOfIssuance: company.value?.province ?? '',
      dateOfIssuance: getToday('YYYY-MM-DD'),
      incorrectItems: [],
    }

    const incorrectInvoiceToCreate = ref()
    const initIncorrectInvoiceToCreate = () => {
      incorrectInvoiceToCreate.value = cloneDeep(blankIncorrectInvoice)
      store.dispatch(`${INCORRECT_INVOICE_STORE}/clearSelectedInvoices`)
    }
    initIncorrectInvoiceToCreate()

    const selectedInvoices = computed(() => store.getters[`${INCORRECT_INVOICE_STORE}/getSelectedInvoices`])
    const isInvalidIncorrectInvoiceItems = ref(false) // check đã chọn ds hoá đơn chưa

    watch(selectedInvoices, () => {
      // Chưa chọn hoá đơn nào => lỗi => chọn hoá đơn => tắt báo lỗi
      if (selectedInvoices.value.length && isInvalidIncorrectInvoiceItems.value) {
        isInvalidIncorrectInvoiceItems.value = false
      }
    })

    async function onCreateIncorrectInvoiceHandle() {
      // Chưa chọn hoá đơn nào => lỗi
      if (!selectedInvoices.value.length) {
        isInvalidIncorrectInvoiceItems.value = true
      }

      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidIncorrectInvoiceItems.value) return

      const payload = {
        ...incorrectInvoiceToCreate.value,
        incorrectItems: selectedInvoices.value.map(inv => ({
          ...inv,
          invoiceDate: `${inv.invoiceDate}T09:00:00Z`, // thêm time
        })),
        companyId: company.value.id,
        dateOfIssuance: `${incorrectInvoiceToCreate.value.dateOfIssuance}T09:00:00Z`, // ngày lập ??
        dateOfTaxReviewNotice: incorrectInvoiceToCreate.value?.dateOfTaxReviewNotice ? `${incorrectInvoiceToCreate.value?.dateOfTaxReviewNotice}T09:00:00Z` : undefined,
      }
      createIncorrectInvoice(payload)
    }

    const collapseSelectedIncorrectInvoices = ref(false)

    function exitHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.confirmCancelInput'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.iamConfirm'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            router.push({ name: 'apps-incorrectInvoices-list' })
          }
        })
    }

    const resetIncorrectInvoices = ref({})

    return {
      refFormObserver,
      blankIncorrectInvoice,
      incorrectInvoiceToCreate,
      onCreateIncorrectInvoiceHandle,
      exitHandle,
      collapseSelectedIncorrectInvoices,
      isInvalidIncorrectInvoiceItems,
      loadingCreate,
      resetIncorrectInvoices,
    }
  },
}
</script>
<style lang="">

</style>
